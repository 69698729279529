var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('b-alert',{staticClass:"cursor-pointer",attrs:{"variant":'primary',"show":""}},[_c('div',{staticClass:"alert-body"},[_c('strong',[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"BookIcon"}}),_vm._v(" Monitoring is more than just watching—it's proactive engagement. With real-time visitor tracking, our agents are always one step ahead, ensuring safety and smooth operations. ")],1)])]),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-observer',{ref:"yoVisitorRule",attrs:{"tag":"form"}},[_c('b-form',{ref:"formListQR",staticClass:"repeater-form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},_vm._l((_vm.form.itemPengunjung),function(item,index){return _c('b-card',{key:item.fe_id,ref:"rowPengunjung",refInFor:true,staticClass:"border-primary repeater-form py-1 px-1",attrs:{"no-body":"","id":item.fe_id}},[_c('b-row',[_c('b-col',{attrs:{"lg":"1","md":"1","cols":"6"}},[_c('b-badge',{staticClass:"mt-0",attrs:{"pill":"","variant":"info"}},[_vm._v(" Data "+_vm._s(index + 1)+" ")])],1),_c('b-col',{staticClass:"d-none d-md-block",attrs:{"lg":"10","md":"10","cols":"3"}},[_c('hr')]),_c('b-col',{attrs:{"lg":"1","md":"1","cols":"6"}},[_c('b-badge',{staticClass:"badge-glow button",attrs:{"pill":"","variant":"danger"},on:{"click":function($event){return _vm.removeItemPengunjung(index)}}},[_c('feather-icon',{style:({ cursor: 'pointer' }),attrs:{"icon":"XIcon"}}),_vm._v(" Hapus ")],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Badge","label-for":("vi-forpe-badge_id-" + index)}},[_c('validation-provider',{attrs:{"name":"Badge","vid":("vi-forpe-badge_id-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":("vi-forpe-badge_id-" + index),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.badgeName,"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","item-value":"value","item-text":"text"},model:{value:(item.badge_id),callback:function ($$v) {_vm.$set(item, "badge_id", $$v)},expression:"item.badge_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Kunjungan ke Perusahaan","label-for":("vi-forpe-visitor_cat_id-" + index)}},[_c('validation-provider',{attrs:{"name":"Kunjungan ke Perusahaan","vid":("vi-forpe-visitor_cat_id-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":("vi-forpe-visitor_cat_id-" + index),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryName,"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","item-value":"value","item-text":"text"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('b-spinner',{attrs:{"small":"","type":"grow","variant":color}}),_c('span',{staticClass:"ml-50 align-middle"},[_c('strong',[_vm._v(_vm._s(text))])])]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('b-spinner',{attrs:{"small":"","type":"grow","variant":color}}),_c('span',{staticClass:"ml-50 align-middle"},[_c('strong',[_vm._v(_vm._s(text))])])]}}],null,true),model:{value:(item.visitor_cat_id),callback:function ($$v) {_vm.$set(item, "visitor_cat_id", $$v)},expression:"item.visitor_cat_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nomor Whatsapp * (Ex: 8155 7777 33)","label-for":("phone-" + index)}},[_c('validation-provider',{attrs:{"name":"Nomor Whatsapp *","vid":("phone-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v("ID (+62)")]),_c('cleave',{staticClass:"form-control",attrs:{"id":("phone-" + index),"raw":false,"options":_vm.options.phone,"placeholder":"8155 7777 33","state":errors.length > 0 ? false : null},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","cols":"12"}},[_c('b-form-group',{attrs:{"label-for":("file_ktp-" + index)}},[_c('validation-provider',{attrs:{"name":"File KTP*","vid":("file_ktp-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:function (el) { return (_vm.refInputEl[index] = el); },refInFor:true,attrs:{"placeholder":"File KTP...","accept":".jpeg, .jpg, .png"},on:{"input":function () { return _vm.inputImageRenderer(item, index); }},model:{value:(item.no_ktp_file),callback:function ($$v) {_vm.$set(item, "no_ktp_file", $$v)},expression:"item.no_ktp_file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(item.extractedNik)?_c('div',[_c('b-card',{attrs:{"border-variant":"success","header-border-variant":"success","header-text-variant":"success"}},[_c('small',[_vm._v("System berhasil membaca "),_c('strong',[_vm._v("Nomor NIK : ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer",attrs:{"title":"Click to copy"},on:{"click":function($event){return _vm.copyText(item.extractedNik)}}},[_vm._v(" "+_vm._s(item.extractedNik)+" "),_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1),_vm._v(", jika benar maka Click nomor NIK (copy) dan paste ke kolom pengisian "),_c('strong',[_vm._v("Nomor KTP")]),_vm._v(". ")])])],1):_vm._e()],1),_c('b-col',{attrs:{"lg":"3","md":"3","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nomor KTP *","label-for":("no_ktp-" + index)}},[_c('validation-provider',{attrs:{"name":"Nomor KTP *","vid":("no_ktp-" + index),"rules":"required|min:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1),_c('cleave',{staticClass:"form-control",attrs:{"id":("no_ktp-" + index),"raw":true,"options":_vm.options.noKTP,"placeholder":"Nomor KTP"},model:{value:(item.no_ktp),callback:function ($$v) {_vm.$set(item, "no_ktp", $$v)},expression:"item.no_ktp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"3","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap *","label-for":("full_name-" + index)}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap *","vid":("full_name-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":("full_name-" + index),"state":errors.length > 0 ? false : null,"placeholder":"Nama Lengkap"},model:{value:(item.full_name),callback:function ($$v) {_vm.$set(item, "full_name", $$v)},expression:"item.full_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Alamat *","label-for":("address-" + index)}},[_c('validation-provider',{attrs:{"name":"Alamat *","vid":("address-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":("address-" + index),"state":errors.length > 0 ? false : null,"placeholder":"C5QH+98X, Rw., Rw. Makmur, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251"},model:{value:(item.address),callback:function ($$v) {_vm.$set(item, "address", $$v)},expression:"item.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Asal Perusahaan / Instansi *","label-for":("from_company-" + index)}},[_c('validation-provider',{attrs:{"name":"Asal Perusahaan / Instansi *","vid":("from_company-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":("from_company-" + index),"state":errors.length > 0 ? false : null,"placeholder":"Asal Perusahaan / Instansi"},model:{value:(item.from_company),callback:function ($$v) {_vm.$set(item, "from_company", $$v)},expression:"item.from_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ket. / Keperluan *","label-for":("ket-" + index)}},[_c('validation-provider',{attrs:{"name":"Ket. / Keperluan *","vid":("ket-" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CoffeeIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":("ket-" + index),"state":errors.length > 0 ? false : null,"placeholder":"Example: Monthly Meeting K3A, Daily Checking, etc"},model:{value:(item.ket),callback:function ($$v) {_vm.$set(item, "ket", $$v)},expression:"item.ket"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)}),1),_c('b-col',{attrs:{"lg":"12","md":"12","cols":"12"}},[_c('b-card',[_c('b-button',{attrs:{"size":"md","variant":"outline-primary","block":""},on:{"click":_vm.repeatePengunjung}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Tambah Pengunjung")])],1)],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',[_c('validation-observer',{ref:"yoScheduleRule",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" C ")]),_vm._v(" Schedule IN ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Planning.")])])]),_c('b-col',[_c('b-form-group',{attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Schedule IN Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-calendar',{attrs:{"block":"","locale":"id-ID"},model:{value:(_vm.form.schedule_in_date),callback:function ($$v) {_vm.$set(_vm.form, "schedule_in_date", $$v)},expression:"form.schedule_in_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Schedule IN Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mt-2",attrs:{"now-button":"","reset-button":"","locale":"id-ID","hour12":false},model:{value:(_vm.form.schedule_in_time),callback:function ($$v) {_vm.$set(_vm.form, "schedule_in_time", $$v)},expression:"form.schedule_in_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('strong',[_c('br'),_c('i',[_vm._v("\" Every scan tells a story of protection and control. \"")])])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" D ")]),_vm._v(" Short Summary List Data QR ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Akan mengirimkan kode QR Ke masing-masing pengunjung secara queue")])])])],1),_c('YoGoodTable',{attrs:{"fields":_vm.tableColumns,"data":_vm.tableDataWatch,"paginationOptions":{ enabled: true, perPage: 10 }}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"disabled":_vm.loadingSaveData,"block":"","variant":"primary"},on:{"click":_vm.formSubmitted}},[(_vm.loadingSaveData)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan data dan Kirim QRCode Ke Whatsapp "+_vm._s(_vm.form.phone ? ("+62 " + (_vm.form.phone)) : "")+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }